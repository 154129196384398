<template>
  <div class="RadioInput">
    <div
      class="RadioInput__Control"
      :class="{
        'order-last': reversed,
      }"
    >
      <button
        :id="id"
        :aria-label="label ? label : undefined"
        class="w-6 h-6 border rounded-full border-primary-1-100 flex items-center justify-center flex-shrink-0"
        type="button"
        :class="{
          'border-primary-900': !disabled,
          'border-grey-100 pointer-events-none': disabled,
          'order-last': reversed,
          'border-system-red': errorMessage && showErrors,
        }"
        @click="onChange"
      >
        <div
          class="w-4 h-4 rounded-full"
          :class="{
            'bg-primary-1-100': checked && !disabled,
            'bg-green-50': checked && disabled,
          }"
        ></div>
      </button>

      <div class="flex flex-1 items-center space-x-4">
        <label
          v-if="label || $slots.default || $slots.labelDescription"
          :for="id"
          class="flex justify-between w-full cursor-pointer"
        >
          <span :class="{ 'opacity-50': disabled }" class="Label">
            <slot v-if="$slots.default" name="default" />
            <span v-else>{{ label }}</span>
          </span>
          <slot name="labelDescription" />
        </label>

        <slot name="description" />
      </div>

      <input :id="id" v-model="fieldValue" :checked="checked" :name="name" type="radio" class="hidden" />
    </div>
    <label v-if="$slots.after" :for="id" class="flex flex-col cursor-pointer">
      <slot name="after" />
    </label>
  </div>
</template>

<script setup lang="ts">
import { get as getPath, isEqual } from 'lodash-es';

// const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  id: {
    type: String,
    default: undefined,
  },
  label: {
    type: String,
    default: undefined,
  },
  name: {
    type: String,
    default: '',
    required: true,
  },
  value: {
    type: null,
    default: '',
  },
  modelValue: {
    type: null,
    default: '',
  },
  rules: {
    type: [String, Object],
    default: '',
  },
  showErrors: {
    type: Boolean,
    default: true,
  },
  trackBy: {
    type: String,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  reversed: {
    type: Boolean,
    default: false,
  },
});

const {
  value: fieldValue,
  setValue,
  errorMessage,
} = useField(
  () => props.name,
  {},
  {
    type: 'radio',
    checkedValue: props.value,
  },
);

const checked = computed(() => {
  if (props.trackBy) {
    return isEqual(getPath(fieldValue.value, props.trackBy), getPath(props.value, props.trackBy));
  }

  return fieldValue.value === props.value;
});

function onChange() {
  if (props.trackBy && getPath(fieldValue.value, props.trackBy) === getPath(props.value, props.trackBy)) {
    setValue(null);
    return;
  }
  setValue(props.value);
}
</script>

<style lang="postcss" scoped>
/* purgecss start ignore */
.RadioInput {
  &__Control {
    @apply flex items-center;
  }
}
/* purgecss end ignore */
</style>
